/* eslint-disable @typescript-eslint/no-non-null-assertion */
import dotenv from 'dotenv';
import * as Logger from '@crimson-education/browser-logger';

if (process.env.REACT_APP_CGA_V2_URL) {
  const { pathname, search } = window.location;
  const redirectUrl = `${process.env.REACT_APP_CGA_V2_URL}${pathname}${search}`;
  console.log(`Redirecting to: ${redirectUrl}`);
  window.location.replace(redirectUrl);
}

dotenv.config({ path: '../.env' });

Logger.init({
  service: 'classroom-frontend',
  environment: process.env.REACT_APP_ENVIRONMENT ?? 'development',
  version: process.env.REACT_APP_VERSION ?? 'no-version',
  defaultMetadata: {
    application: 'classroom',
  },

  // amplify:
  //   process.env.REACT_APP_AMPLIFY_AWS_REGION &&
  //   process.env.REACT_APP_AMPLIFY_IDENTITY_POOL_ID &&
  //   process.env.REACT_APP_AMPLIFY_ANALYTICS_APP_ID
  //     ? {
  //         region: process.env.REACT_APP_AMPLIFY_AWS_REGION,
  //         identityPoolId: process.env.REACT_APP_AMPLIFY_IDENTITY_POOL_ID,
  //         analyticsAppId: process.env.REACT_APP_AMPLIFY_ANALYTICS_APP_ID,

  //         autoTrackEvents: true,
  //         autoTrackPageViews: true,
  //         autoTrackSessions: true,
  //         ignoreMetadataPatterns: [
  //           /streamId-.*/,
  //           /event\.target\.capabilities.*/,
  //           /event\.target\.connection\.permissions.*/,
  //         ],
  //       }
  //     : undefined,
});

// Dynamic import so any module reporting, or globals can be registered.
import('./App').then((app) => app.start());
